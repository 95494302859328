<template>
  <tran :tranMode=tranMode></tran>
</template>

<script>
// @ is an alias to /src
import Tran from '@/views/Tran/Tran';


export default {
  name: 'EditTran',
  components: {
    Tran
  },
  data(){
    return{
      tranMode:'edit'
    }
  },
  methods:{

  },
  mounted() {
    document.title = "Edit Transaction";
  }
}
</script>

<style scoped>



</style>